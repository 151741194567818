<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              :show-delete="true"
              :show-languages="false"
              :show-currencies="false"
              :show-select="false"
              path="warehouses::income::manualIncome"
              v-model:items="items"
              v-model:selectedLineOption="selectedLineOption"
              v-model:selectedItems="selectedItems"
              v-on:addNewRow="addNewIncome"
              v-on:delete="deleteIncomings"
              :exportUrl="getUrl(true)"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="items"
              v-model:select-all="selectAll"
              :show-order="false"
              :show-pin="false"
              v-on:selected="(e) => (selectedItems = e)"
              type="products"
              :key="tableKey"
              :fix-height="true"
              v-on:loadMore="loadMore"
            />
          </div>
        </div>
      </div>
    </div>
    <NewManualIncoming
      v-model:show="addNew"
      v-on:newItem="newItem"
      v-model:partners="partners"
    />
    <ConfirmModal
      ref="noWarehouseModal"
      :title="$t(`manualIncoming.noWarehouseSelected`)"
      text="please select a warehouse before adding a new income!"
      :show-ok="false"
      cancel-button="Ok"
    />
  </div>
</template>

<script>
import TopBar from "../../components/lists/TopBar";

import NewManualIncoming from "../../components/modals/NewManualIncoming";
import http from "../../modules/http";
import { useStore } from "vuex";
import DynamicTable from "../../components/lists/DynamicTable";
import ConfirmModal from "../../components/modals/ConfirmModal";
import lineOptions from "../../modules/lineOptions";
import ws from "../../modules/ws";
import incomingstatuses from "@/modules/Incomingstatuses";
import ItemOperationTypes from "@/modules/ItemOperationTypes";
export default {
  name: "ManualIncoming",
  components: {
    ConfirmModal,
    DynamicTable,
    NewManualIncoming,
    TopBar,
  },
  data() {
    return {
      store: useStore(),
      addNew: false,
      tableKey: 0,
      rows: [],
      selectedLineOption: 1,
      selectedItems: [],
      categories: [],
      lineOptions: [],
      selectAll: false,
      items: null,
      page: 1,
      filtersTimeOut: null,
      partners: [],
      loadID: null,
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    date() {
      return this.store.state.topBar.date;
    },
    sessionId() {
      return this.store.state.sessionId;
    },
    q() {
      return this.store.state.topBar.q;
    },
    filterPage() {
      return this.store.state.filterPage;
    },
    filters() {
      return this.store.state.filters?.manual_incomings;
    },
  },
  watch: {
    warehouse() {
      this.loadData();
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.loadData();
      }, 450);
    },
    filters: {
      deep: true,
      handler() {
        if (this.filterPage !== "manual_incomings" || this.firstLoad) {
          setTimeout(() => {
            this.firstLoad = false;
          }, 100);
          return;
        }
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.page = 1;
          this.loadData();
        }, 450);
      },
    },
    date: {
      deep: true,
      handler() {
        this.page = 1;
        this.loadData();
      },
    },
  },
  mounted() {
    this.loadData();

    ws.init();

    ws.subscribe("add", "manualIncoming", (e) => {
      if (e.session !== this.sessionId) {
        if (this.warehouse !== null || e.object.warehouse !== this.warehouse) {
          return;
        }
        if (
          this.date[1] !== null ||
          this.date[1].toISOString().split("T")[0] !==
            Date.toISOString().split("T")[0]
        ) {
          return;
        }
        this.items.unshift(e.object);
        this.tableKey++;
      }
    });

    ws.subscribe("modify", "manualIncoming", (e) => {
      let obj = this.items[this.items.findIndex((o) => o.id === e.objectId)];
      if (obj !== undefined) {
        if (e.session !== this.sessionId) {
          obj[e.rowKey] = e.value;
        }
      }
    });
  },
  methods: {
    getUrl(exports = false) {
      let url =
        `/warehouses/income/manual${exports ? "/export/{type}" : ""}?page=${
          exports ? 1 : this.page
        }` +
        (this.warehouse ? "&warehouse=" + this.warehouse : "") +
        (this.q ? "&q=" + this.q : "") +
        (this.date[0]
          ? "&from=" + this.date[0].toISOString().split("T")[0]
          : "") +
        (this.date[1] ? "&to=" + this.date[1].toISOString().split("T")[0] : "");

      if (
        Object.keys(this.filters)?.length > 0 &&
        this.filterPage === "manual_incomings"
      ) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&${filter}[]=${value}`;
            }
          } else {
            url += `&${filter}=${this.filters[filter].value}`;
          }
        }
      }
      return url;
    },
    newItem(data) {
      this.items.unshift(data);
      this.tableKey++;
    },
    addNewIncome() {
      if (this.warehouse === null) {
        this.$refs.noWarehouseModal.showModal();
      } else {
        this.addNew = true;
      }
    },
    deleteIncomings() {
      let data = [];
      for (const item of this.selectedItems) {
        data.push(item.id);
      }
      http
        .fetch(`/warehouses/income`, { rows: data }, true, "DELETE")
        .then(() => {
          for (const item of this.selectedItems) {
            let i = this.items.indexOf(item);
            if (i > -1) {
              this.items.splice(i, 1);
            }
          }
          this.tableKey++;
        });
    },
    setUpCategories(data, str = "") {
      if (data.categories !== undefined) {
        for (const category of data.categories) {
          this.setUpCategories(
            category,
            data.name !== undefined ? `${str}${data.name} - ` : ""
          );
        }
      } else {
        this.categories.push({
          name: `${str}${data.name}`,
          top: data.name,
          sub: str,
          id: data.id,
        });
      }
    },
    setFilterOptions() {
      let filterOptions = [];
      this.$store.commit("setFilterPage", "manual_incomings");

      filterOptions.push({
        name: "Type",
        type: "string",
        key: "type",
        value: {},
        values: [
          {
            id: 2,
            name: "product income",
          },
          {
            id: 5,
            name: "Material income",
          },
          {
            id: 6,
            name: "Production",
          },
        ],
        valueName: "name",
      });

      filterOptions.push({
        name: "Status",
        type: "string",
        key: "status",
        value: {},
        values: Object.values(incomingstatuses),
        valueName: "name",
      });

      filterOptions.push({
        name: "Categories",
        type: "string",
        key: "categories",
        value: {},
        values: this.categories,
        valueName: "name",
      });

      filterOptions.push({
        name: "Partners",
        type: "string",
        key: "partners",
        value: {},
        values: this.partners,
        valueName: "name",
      });

      for (const obj of this.characteristics) {
        let max =
          obj.characteristics_values_max_val_double !== null
            ? obj.characteristics_values_max_val_double
            : obj.characteristics_values_max_val_int;
        filterOptions.push({
          name: obj.name,
          type: obj.type,
          key: "characteristics[" + obj.id + "]",
          value: obj.type === "number" ? [0, max] : {},
          max: max,
          values: obj?.characteristics_values,
          prefix: obj?.prefix,
          postfix: obj?.postfix,
        });
      }

      this.$store.commit("setFilterOptions", filterOptions);
      this.$store.commit("setFilterOptionsStock", false);
      this.$store.commit("setFilterOptionsPrice", false);
    },
    loadData() {
      const loadID = (this.loadID = Math.random());
      if (this.categories.length === 0) {
        http.fetch(`/products/products/categories?language=en`).then((data) => {
          this.categories = [];
          this.setUpCategories({ categories: data.data });
        });
      }
      if (this.partners.length === 0) {
        http.fetch("/crm/partners/has-price").then((data) => {
          this.partners = data;
          if (this.characteristics.length > 0) {
            this.setFilterOptions();
          }
        });
      }
      if (this.filterPage !== "manual_incomings") {
        this.$store.commit("setFilterOptions", []);

        http
          .fetch("/products/products/characteristics?language=en")
          .then((data) => {
            this.characteristics = data;
            if (this.partners.length > 0) {
              this.setFilterOptions();
            }
          });
      }
      if (this.page === 1) {
        this.items = undefined;
        this.tableKey++;
      }

      http.fetch(this.getUrl()).then((data) => {
        if (this.loadID !== loadID) {
          return;
        }
        if (this.page === 1) {
          this.rows = lineOptions.init(data.rows, data.line_options);
          this.lineOptions = data.line_options;
          this.selectedLineOption = data.line_options[0].id;

          let status = this.rows.find((e) => e.key === "status");
          status.values = incomingstatuses;
          status.center = true;

          let type = this.rows.find((e) => e.key === "operation_type");
          type.values = ItemOperationTypes;
          type.center = true;

          this.items = data.data;
          this.tableKey++;
        } else {
          for (const row of data.data) {
            this.items.push(row);
          }
        }
      });
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
  },
};
</script>
